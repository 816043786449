.footer {
	padding: $footer-padding;

	.footer-top {
		padding: 80px 0;
		background: $white;

		.page_block.footer_logolink {
			display: flex;
			justify-content: center;

			.list {
				justify-content: center;

				li.list-item {
					margin: 7px 15px 7px 15px;
				}
			}
		}
	}
	section.footer-middle {
		background: $highlight;
		padding: 40px 0;

		.container-holder {
			@extend .container;
			color: #3b3b3b;

			.column {
				
				h4 {
					font-size: $h3-font-size;
					color: #333;
				}
				&.one {
					padding-bottom: 0;

					.page_block.footer_text {
						display: flex;
						flex-direction: column;
					}
					&::after {
						content: "";
						border-bottom: 2px solid $primary;
						height: 1px;
						width: 100%;
						position: absolute;
						right: 0;
						bottom: 0;
					}
					@include media-breakpoint-up(md) {
						text-align: right;
						padding-right: 40px !important; // !important was needed to override base styles

						&::after {
							content: "";
							border-right: 2px solid $primary;
							height: 100%;
							width: 1px;
							position: absolute;
							right: 0;
							top: 0;;
						}
						.page_block.footer_text {
							align-items: flex-end;
						}
					}
					a {
						@extend .btn-white;
					}
				}
				&.two {
					@include media-breakpoint-up(md) {
						padding-left: 40px !important; // !important was needed to override base styles
					}
					ul.page_block.vocabulary_term_list {
						padding: 0;
						margin: 10px 0 0;
						border: none;
						li {
							a {
								color: $white;
								font-size: 30px;
								&:hover {
									color: darken($white, 10%);
								}
							}
							+ li { margin-left: 15px;}
						}
					}
				}
				p {
					max-width: 45%;

					&:last-child {
						margin: 0;
					}
				}
			}
		}
	}

	.footer-socket {
		padding: 0;

		.footer-socket-top {
			padding: 40px 0;

			.container-holder {
				display: flex;
				flex-direction: column;
				align-items: center;

				.page_block.footer_text {
					color: $white;
					text-align: center;
					margin-top: 20px;
					a[href^='tel:'],
					a[href^='mailto:'] {
						color: #fff;
						font-size: 18px;
						font-weight: 700;
					}
				}
			}
		}
		.footer-socket-middle {
			@extend .container;
			padding: 40px 0;

			.container-holder {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;

				.page_block.footer_list {
					@include make-col(6);
					text-align: center;
					margin-bottom: 20px;

					@include media-breakpoint-up(md) {
						@include make-col(4);
						margin-bottom: 0;
					}
					@include media-breakpoint-up(lg) {
						flex: 0 0 20%;
						max-width: 20%;
					}
					h5 {
						color: $yellow-label;
						font-weight: bold;
						font-size: $font-size-base;
					}
					ul.list {
						li {
							a {
								@extend .link-white;
							}
						}
					}
				}
			}
		}
		.footer-socket-bottom {
			background: $white;
			padding: 5px 0;

			.page_block.footer_link {
				display: flex;
				padding: 10px 20px;

				@include media-breakpoint-up(md) {
					justify-content: center;

					ul {
						li {
							&:not(:last-child):after {
								content: "//";
								margin-left: 10px;
							}
						}
					}
				}
				ul {
					li {
						a {
							font-size: $font-size-base_xs;
						}
					}
				}
			}
		}
	}
}
