.container-default .container-holder {
	.page_block {
		&.contentblock {
			&.card-wide-grid {
				display: flex;
				flex-direction: column;
				padding: 20px 0 0;

				.item {
					@include make-col(12);

					&:not(:last-child) {
						margin-bottom: 40px;
					}
					.card {
						.card-image {
							@include make-col(12);

							@include media-breakpoint-up(md) {
								@include make-col(7);
							}
							.card-image-link {
								height: 100%;

								&:hover .card-img {
									transform: scale(1.2);
								}
								@include media-breakpoint-up(md) {
									.card-img {
										transform: scale(2.5);
									}
								}
								@include media-breakpoint-up(lg) {
									.card-img {
										transform: scale(1.2);
									}
									&:hover .card-img {
										transform: scale(1.3);
									}
								}
							}
						}
						.card-body {
							@include make-col(12);
							padding-top: 50px;

							@include media-breakpoint-up(md) {
								@include make-col(5);
							}
							* {
								text-align: center;
							}
							.card-subtitle {
								font-family: $font-dm;
								font-style: italic;
								font-weight: bold;
							}
							.card-title-link {
								.card-title {
									font-family: $font-fredericka;
									font-size: $font-size-base * 2.25;
									font-weight: 400;
								}
							}
							.card-description {
								color: $white;
								margin-top: 0;
								max-width: 285px;
								margin: auto;

								a {
									color: $white;
									text-decoration: underline;
								}
							}
							a.card-btn {
								@extend .btn-white;
							}
						}
					}

					@include media-breakpoint-up(md) {
						&:first-child {
							.card {
								display: flex;

								.card-image {
									order: 2;
								}
								.card-body {
									order: 1;
								}
							}
						}
						&:last-child {
							.card-body {
								background: $secondary;

								a.card-btn {
									color: $secondary;

									&:hover {
										color: $secondary;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
