.owl-carousel {
	//min-height: $carousel-min_height;

	@include media-breakpoint-up(sm) {
		//min-height: $carousel-min_height_lg;
	}
	&.contentblock {
		.item {
			position: relative;
			//min-height: $carousel-min_height;

			@include media-breakpoint-up(md) {
				//min-height: $carousel-min_height_lg;
			}
			.owl-container {
				max-width: 730px;

				.owl-caption {
					text-align: center;
					display: flex;
					flex-direction: column;
					align-items: center;
					margin-bottom: 20px;

					.owl-title {
						margin-bottom: 30px;
						font-family: $font-lemon-tuesday;
						font-size: $font-size-base * 2.5;
						font-weight: 400;
						line-height: 1.5;

						@include media-breakpoint-up(lg) {
							font-size: $font-size-base * 5;
						}
					}
					.owl-description {
						font-size: $font-size-base_sm;
						letter-spacing: 1px;
						margin-bottom: 40px;

						.list.list-icons {
							display: flex;
							flex-direction: column;
							align-items: center;
						}
					}
					.owl-btn {
						@extend .btn-highlight;
					}
				}
			}
		}
	}
	.owl-dots {
		margin-top: 24px;
		bottom: 50px;

		.owl-dot {
			span {
				background: $primary !important; // !important was needed to override base styles
				border: 1px solid $border-color !important; // !important was needed to override base styles;
				width: 20px;
				height: 20px;
				box-shadow: none !important; // !important was needed to override base styles;

			}
			&.active {
				span {
					background: $highlight !important; // !important was needed to override base styles
				}
			}
		}
	}

	.owl-nav {
		left: 80px;
		right: 80px;

		@include  media-breakpoint-down(md) {
			display: none;
		}
		.owl-next,
		.owl-prev {
			color: $owl-nav-arrow-color;
			background: $white;
			border-radius: 50%;
			width: 36px;
			height: 36px;
			font-size: $font-size-base * 1.5;
			text-shadow: none;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}
.slider.page_block.contentblock {
	min-height: auto !important;  // !important was needed to override base styles

	.item {
		min-height: auto !important;  // !important was needed to override base styles
	}
	.owl-nav {
		right: -40px;
		left: -60px;

		@include  media-breakpoint-down(lg) {
			display: none;
		}
		.owl-next,
		.owl-prev {
			color: $white;
			background: $primary;

			&:hover {
				text-shadow: 2px 2px 2px rgba($black, 0.5);
			}
		}
	}
	.owl-dot {
		span {
			border: none !important; // !important was needed to override base styles
		}
	}
}
