.page_block {
	.card {
		.card-body {
			background: $primary;

			.card-caption {
				display: flex;
				flex-direction: column;

				.card-title-link {
					order: 2;
				}
				.card-subtitle {
					order: 1;
					letter-spacing: 1px;
				}
				.card-description {
					order: 3;
					letter-spacing: 1px;

					.card-description-content {
						@extend .card-description-content-block;
					}
					.list.list-icons {
						margin: 20px 0 0;

						.list-item {
							font-size: $font-size-base_xs;
							font-style: italic;

							.icon {
								i[class^=fal] {
									font-size: $font-size-base_sm;
									color: $highlight;
								}
							}
						}
					}
					&::after {
						content: none;
					}
				}
			}
			.card-buttons {
				margin: 20px 0;

				.card-btn {
					@extend .btn-highlight;
				}
			}
		}
		.card-image {
			.card-image-link {
				overflow: hidden;

				&:hover {
					.card-img {
						transform: scale(1);
					}
				}
				.card-img {
					width: 100%;
					height: auto;
					transition: all 0.5s ease-in-out;
					transform: scale(1.1);
				}
			}
		}
	}
}

//Grid-block and Slider card block styling
.grid.page_block.contentblock,.slider.page_block.contentblock {
	.card {
		.card-body {
			.card-caption {
				.card-description {
					margin-top: 40px;
				}
			}
		}
	}
}

//Slider card styling
.slider.page_block.contentblock {
	.card {
		width: calc(100% - 20px);
	}
}

//Card overlay
.item {
	.card.card-overlay {
		transition: all .5s ease-in;

		.card-image {
			display: block;
			width: 100%;
			height: auto;
		}
		.card-img-overlay {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			height: 100%;
			width: 100%;
			opacity: 1;
			visibility: none;
			transition: .5s ease;
			background-color: rgba($black, 0.2);

			.card-caption {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 90%;
				text-align: center;
				letter-spacing: 1px;

				.card-title {
					text-align: center;
					text-shadow: none;
				}
				.card-subtitle {
					opacity: 0;
					height: 0;
					margin-bottom: 0;
					overflow: hidden;
					text-align: center;
					text-shadow: none;
				}
				.card-description {
					min-height: 0;
					height: 0;
					overflow: hidden;
					opacity: 0;
					max-height: 100%;
					text-align: center;
					text-shadow: none;

					&::after {
						content: none;
					}
					.card-description-content {
						@extend .card-description-content-block;
						height: auto;
					}
					.list {
						display: inline-block;
						text-shadow: none;
					}
				}
			}
		}
		&:hover {
			.card-img-overlay {
				background-color: rgba($black, 0.5);
				.card-caption {
					.card-subtitle,
					.card-description {
						opacity: 1;
						height: auto;
					}
				}
			}
		}
	}
	&:first-child {
		.card-img-overlay {
			opacity: 1 !important; // !important was needed to override card image overlay style
			background: $secondary !important;  //important was needed to override card image overlay style
			.card-caption {
				letter-spacing: 0 !important;
				.card-title {
					text-shadow: none;
					font-size: 20px;
					font-weight: 500;
					font-style: italic;
				}
				.card-subtitle {
					opacity: 1 !important;
					height: auto !important;
					font-size: 36px;
					font-weight: 400;
					font-family: $font-fredericka;
					text-shadow: none;
				}
				.card-description {
					opacity: 1 !important;
					height: auto !important;
					min-height: auto;
					max-height: none;
					margin-top: 30px;
					font-size: 12px;
					text-shadow: none;
					.card-description-content {
						height: auto !important;
					}
				}
			}
		}
	}
}

//Vocabulary card

.two.bg-dark {
	background: $bg-base-1 !important;
	margin: 20px 0 40px;

	.container-default {
		@extend .container;
	}
	.page_block.vocabulary_term_collection {
		.item {
			margin: 0;

			.card {
				padding: 0 0 20px;
				height: inherit !important;

				@include media-breakpoint-up(sm) {
					padding: 50px 0 20px;
				}

				.card-body {
					background: none;
					padding: 0;
					position: relative;

					&::before {
						content: url('/images/vshape.svg');
						position: absolute;
						left: 0;
						top: 0;
						width: 40px;
					}
					.card-caption {
						padding-left: 60px;

						@include media-breakpoint-down(lg) {
							max-width: 280px;
						}
						@include  media-breakpoint-down(sm) {
							max-width: 100%;

						}
						.card-title {
							font-size: $h3-font-size;
						}
						.card-description {
							&::after {
								content: none;
							}

							.card-description-content {
								height: auto;
							}
						}
					}
				}
			}
			&:first-child {
				@include media-breakpoint-down(sm) {
					padding-top: 50px;
				}
			}
			&:last-child {
				@include media-breakpoint-down(sm) {
					padding: 0 0 20px;
				}
			}
			&:not(:last-child) {
				.card {
					.card-body {
						&::after {
							content: "";
							border-right: 2px solid $primary;
							height: 100%;
							width: 1px;
							position: absolute;
							right: 0;
							top: -10px;

							@include media-breakpoint-down(sm) {
								content: none;
							}
						}
					}
				}
			}
			@include media-breakpoint-down(md) {
				&:nth-child(2) {
					.card {
						.card-body {
							&::after {
								content: none;
							}
						}
					}
				}
			}
		}
	}
}
