.eyecatcher {
	position: relative;
	background: $green;

	.page_block.logo {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		top: 110px;
		z-index: 99;

		@include media-breakpoint-up(sm) {
			top: 50px;
		}
	}

	.owl-carousel {

		.item {
			padding: 0;

			.owl-container {
				margin-top: 120px;

				@include media-breakpoint-up(sm) {
					margin-top: 200px;
				}
				.owl-caption {
					.owl-title {
						font-family: $font-lemon-tuesday;
						font-size: $font-size-base * 2.5;
						font-weight: 400;
						text-align: center;
						line-height: 1.5;
						@include media-breakpoint-up(lg) {
							font-size: $font-size-base * 5;
						}
					}
				}
			}
		}
		.owl-nav {
			display: none;
		}
	}
}
