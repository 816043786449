.header {
	position: fixed !important; // !important was needed to override base styles
	width: 100%;

	/* Header-socket
     ========================================================================== */

	.header-socket {
		background: none !important; // !important was needed to override base styles
		margin-top: 10px;
		position: absolute;
		right: 0;
		width: 100%;

		@include media-breakpoint-up(sm) {
			width: auto;
		}

		.container-navbar {
			nav.column.navbar {
				display: flex;
				justify-content: space-between !important; // !important was needed to override base styles

				@include media-breakpoint-up(sm) {
					flex-direction: column !important; // !important was needed to override base styles
					align-items: flex-end;
				}

				button.navbar-toggler.page_block.menu_toggle {
					display: flex !important; // !important was needed to override base styles
					background: $yellow-label;
					border-radius: 40px;
					color: $black !important; // !important was needed to override base styles
					padding: 8px 15px;
					align-items: center;
					border: none;
					font-size: $font-size-base;
					z-index: 999;
					font-weight: 700;
					position: relative;

					@include media-breakpoint-up(sm) {
						font-size: $h3-font-size;
						margin-bottom: 10px;
					}

					&:hover {
						background: darken($yellow-label, 10%);
					}

					span.navbar-toggler-icon {
						margin: 0;
						width: auto;
						height: auto;
						margin-right: 10px;
					}

					&[aria-expanded="true"] {
						.fa-bars {
							&::before {
								content: "\f00d";
							}
						}

						position: fixed !important; // !important was needed to override base styles
						top: 22px;
						left: 16px;

						@include media-breakpoint-up(sm) {
							top: 18px;
							right: 16px;
							left: unset;
						}

						+ a.btn.btn-search-book {
							position: fixed !important; // !important was needed to override base styles
							top: 18px;
							right: 16px;

							@include media-breakpoint-up(sm) {
								top: 63px;
								right: 16px;
							}
						}
					}
				}

				a.btn.btn-search-book {
					display: flex;
					flex-direction: column;
					background: $highlight;
					border-radius: 40px;
					z-index: 999;
					position: relative;
					text-align: right;
					padding: 2px 15px;

					&:hover {
						background: darken($highlight, 10%);
						color: $white;
					}

					span.title {
						font-size: $font-size-base_xxs;
					}

					span.subtitle {
						font-size: $font-size-base;
						line-height: 1;
						font-weight: 700;

						@include media-breakpoint-up(sm) {
							font-size: $h3-font-size;
						}
					}
				}

				.site_switcher {
					@include media-breakpoint-down(xs) {
						position: absolute;
						top: 50px;
						right: 15px;
					}
					.navbar-nav {
						.nav-item {
							.nav-link {
								display: block;
								padding-right: 0.25rem;
								padding-left: 0.25rem;
								border-radius: 100%;
								overflow: hidden;

								img {
									display: block;
									width: 22px;
									height: 22px;
									border-radius: 100%;
									transition: 0.5s;
								}

								&:hover:not(.active) {
									img {
										opacity: 0.85;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.header-overlay {
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background: rgba($black, 0.8);
		display: flex;
		max-height: 100vh;
		overflow: auto;
		width: 100vw;
		padding-top: 80px;

		@include media-breakpoint-up(md) {
			padding: 200px 20px 0;
		}
		@include media-breakpoint-up(xl) {
			padding: 200px 100px 0;
		}

		&:not(.show) {
			display: none;
		}

		.container-two-columns.container-fluid {
			.column.one {
				@include make-col(12);

				@include media-breakpoint-up(md) {
					@include make-col(8);
				}

				.menu {
					display: block;

					> ul.navbar-nav {
						flex-direction: row;
						flex-wrap: wrap;

						@include media-breakpoint-up(md) {
							column-count: 2;
							display: block;
						}
						@include media-breakpoint-up(lg) {
							column-count: 3;
							display: block;
						}

						> li.nav-item {
							@include make-col(12);

							&:not(:last-child) {
								margin-bottom: 20px;
							}

							@include media-breakpoint-up(md) {
								break-inside: avoid-column;

								&:not(:last-child) {
									margin-bottom: 40px;
								}
							}
							@include media-breakpoint-up(xl) {
								@include make-col(4);
							}

							&.first {
								padding-left: 22px;
								position: relative;

								&::before {
									content: "\f015";
									font-weight: bold;
									color: $highlight;
									margin-right: 5px;
									position: absolute;
									left: 0;
									font-family: $font-awesome;
								}
							}

							&:not(.first) {
								> a {
									pointer-events: none;
								}
							}

							> a {
								font-size: $h3-font-size;
								font-weight: bold;
								padding: 0;
								cursor: auto;
								color: $highlight;

								&:after {
									content: none;
								}
							}

							ul.dropdown-menu {
								display: block;
								background: none;
								border: none;

								li {
									a {
										@extend .link-white;
										font-size: 16px;
										padding: 0;

										&:hover {
											text-decoration: underline;
										}
									}
								}
							}
						}
					}
				}
			}

			.column.two {
				@include make-col(12);
				color: $white;

				@include media-breakpoint-up(md) {
					@include make-col(4);
				}

				.page_block {
					a {
						color: $white;
					}
				}

				.page_block.logo {
					margin-bottom: 10px;
				}

				.page_block.footer_text {
					p:last-child {
						font-size: $h3-font-size;
					}
				}

				ul.page_block.vocabulary_term_list {
					padding: 0;
					border: none;

					li {
						a {
							@extend .link-white;
							font-size: $font-size-base * 1.875;
						}
					}
				}
			}
		}
	}
}
