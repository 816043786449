/* bootstrap > theme-colors
   ========================================================================== */
$primary: #657925 !default;
$secondary: #F25916 !default;

/* bootstrap > font
	========================================================================== */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredericka+the+Great&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
@font-face {
	font-family: 'Lemon Tuesday';
	font-weight: normal;
    font-style: normal;
	src: url('/fonts/lemon-tuesday.otf');
}
$font-lemon-tuesday: 'Lemon Tuesday', sans-serif;
$font-dm: 'DM Sans', sans-serif;
$font-fredericka: 'Fredericka the Great', cursive;
$font-montserrat: 'Montserrat', sans-serif;
$font-awesome: "Font Awesome 5 Pro";

$font-family-base: $font-dm !default;

$font-size-base: 1rem !default; //16px
$font-size-base_sm: 0.875rem !default; //14px
$font-size-base_xs: 0.813rem !default; //13px
$font-size-base_xxs: 0.75rem !default; //12px

$h1-font-size: $font-size-base * 3 !default;
$h2-font-size: $font-size-base * 1.5 !default;
$h3-font-size: $font-size-base * 1.125 !default;
$h4-font-size: $font-size-base !default;
$h5-font-size: $font-size-base_sm !default;
$h6-font-size: $font-size-base_xs !default;
