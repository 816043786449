// Common reusable placeholders classes
.flex-center {
	display: flex;
	align-items: center;
}
.list-default {
	margin: 0;
	padding: 0;
	list-style: none;
}
.link-white {
	color: $white;

	&:hover {
		color: $highlight;
	}
}

//common element styles
a {
	color: $black;

	&:hover {
		color: $highlight;
	}
}
p {
	font-size: $font-size-base_sm;
	letter-spacing: 1px;
}
img {
	max-width: 100%;
}
h1 {
	margin: 0 0 30px;
	font-family: $font-fredericka;
	font-size: $font-size-base * 2.25;
	font-weight: 400;
	color: $primary;

	@include media-breakpoint-up(md) {
		font-size: $h1-font-size;
	}
}
h2 {
	font-size: $font-size-base * 1.5;

	@include media-breakpoint-up(md) {
		font-size: $h2-font-size;
	}
}
h2,h3 {
	color: $gray;
	letter-spacing: 1px;
}
h3 {
	margin: 24px 0;
}

//Common card
.container-default {
	.container-holder {
		.grid.page_block {
			@include media-breakpoint-up(lg) {
				.item {
					@include make-col(4);
				}
			}
		}
	}
}

//Masonary card column
.page_block.card-columns {
	@include media-breakpoint-up(xl) {
		column-count: 3;
	}
}

//Section padding
section.one.intro,
section.three,
section.four,
section.six,
section.seven,
section.eight,
section.nine,
section.ten,
section.eleven,
section.twelve {
	padding: 80px 0 0 !important;  // !important was needed to override base styles
}

//Text center block
.container.text-center {
	@extend .flex-center;
	max-width: 730px;

	h2 {
		font-style: italic;
		font-weight: 500;
	}
}

//Card Description

.card-description-content-block {
	display: -webkit-box;
	-webkit-line-clamp: 5;
	overflow: hidden;
	text-overflow: ellipsis;
	height: 106px;
	-webkit-box-orient: vertical;
}

//Font awesome icon styling
.list.list-icons {
	.list-item {
		.icon {
			i[class^=fal] {
				font-weight: 600;
			}
		}
	}
}

//Intro section
.intro.text-center {
	padding: 0 30px 60px;

	.container-holder {
		.btn {
			@extend .btn-highlight;
		}
	}
}
