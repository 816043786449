&.test_page {
	section.six {
		.container-default {
			@extend .container;
		}
		.container-holder {
			.column.two {
				ul {
					padding-left: 16px;
					padding-bottom: 40px;
					border-bottom: 1px solid $primary;

					li {
						font-size: $font-size-base_sm;
						letter-spacing: 1.5px;

						a {
							color: $highlight;
							text-decoration: underline;

							&:hover {
								color: darken($highlight, 10%);
							}
						}
					}
				}
			}
		}

	}
	.two.bg-dark {
		.container-default {
			@extend .container;
		}
	}
}
