.btn-highlight {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: 7px 36px;
	font-family: $font-montserrat;
	font-size: $font-size-base_sm;
	font-weight: 600;
	color: $gray;
	border-radius: 18px;
	border-color: $highlight;
	background: $highlight!important;
	text-decoration: none;
	transition: 0.1s ease-in-out;
	min-width: 150px;

	&:hover,
	&:focus {
		color: $white;
		background: lighten($highlight, 10%);
	}
	&:active {
		color: $white;
		background: $primary;
	}
}

.btn-white {
	min-width: 150px;
	background: $white !important;
	color: $primary;
	border: 1px solid $white;
	padding: 7px 15px;

	&:hover {
		color: $primary;
		background: darken($white, 10%) !important;
		border: 1px solid $white;
	}
}
