
/* Container-one-column
   ========================================================================== */
.container-one-column {
	max-width: 730px;

	.container-holder {
		.column.default {
			ul {
				padding-left: 16px;
				padding-bottom: 40px;
				border-bottom: 1px solid $primary;

				li {
					font-size: $font-size-base_sm;
					letter-spacing: 1px;

					a {
						color: $highlight;
						text-decoration: underline;

						&:hover {
							color: darken($highlight, 10%);
						}
					}
				}
			}
			.btn {
				@extend .btn-highlight;
			}
		}
	}
}


/* Container-two-columns
   ========================================================================== */
.container-two-columns {
	.column.one,
	.column.two {
		.page_block.form {
			max-width: 410px;
			padding: 40px 0 40px;

			@include media-breakpoint-up(sm) {
				padding: 40px 0 80px;
			}
			form {
				.form-group {
					label {
						font-weight: 500;
					}
					.btn-primary {
						@extend .btn-highlight;
						margin: 16px 0;
					}
				}
				.form-titleelement {
					padding-top: 8px;

					h3 {
						font-size: $h2-font-size;
					}
				}
				.reCAPTCHA-text {
					font-style: italic;
					font-size: $font-size-base_xs;

					a {
						color: $highlight;
						text-decoration: underline;

						&:hover {
							color: darken($highlight, 10%);
						}
					}
				}
				ul {
					padding-left: 16px;
					padding-bottom: 24px;

					li {
						letter-spacing: 1px;
						font-size: $font-size-base_sm;

						a {
							color: $highlight;
							text-decoration: underline;

							&:hover {
								color: darken($highlight, 10%);
							}
						}
					}
				}
			}
			small {
				font-style: italic;
				font-size: $font-size-base_xs;

				a {
					color: $highlight;
					text-decoration: underline;

					&:hover {
						color: darken($highlight, 10%);
					}
				}
			}
		}
	}
	.column.one {
		.btn {
			@extend .btn-highlight;
		}
	}
	.column.one.form-transparent {
		.page_block.form {
			@include media-breakpoint-down(sm) {
				padding: 0 0 40px;
			}
		}
	}
	/*
	.column.two.form-dark {
		background: $primary;
		display: flex;
		justify-content: center;

		.page_block.form {
			form {
				.form-group {
					label {
						color: $white;
					}
					.reCAPTCHA-text {
						color: $white;

						a {
							&:hover {
								color: lighten($highlight, 10%);
							}
						}
					}
					.btn-primary {
						border-color:$border-color !important; // !important was needed to override button styles
					}
				}
				.form-titleelement,.form-contentelement {
					h3,
					p{
						color: $white;
					}
					ul {
						li {
							color: $white;
							a {
								&:hover {
									color: lighten($highlight, 10%);
								}
							}
						}
					}
				}
			}
			small {
				color: $white;

				a {
					&:hover {
						color: lighten($highlight, 10%);
					}
				}
			}
		}
	}
	*/
}