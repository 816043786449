//Color variables

$highlight: #B0C93F;
$gray: #333333;
$border-color: #E1E1E1;
$yellow-label: #FFE87F;

// Background variables
$bg-base-1: #3B3B3B;

/* ==========================================================================
   body
   ========================================================================== */
$body-padding: 0 !default;
$body-sticky-padding: 0 !default;

/* ==========================================================================
   mini-sab
   ========================================================================== */
$mini-sab-padding: 30px 0 !default;
$mini-sab-background: $primary !default;


/* footer > footer-top
   ========================================================================== */
$footer-top-padding: 50px 0 !default;
$footer-socket-background: $primary !default;

/* content > h1
   ========================================================================== */
$content-h1-margin: 0 0 30px !default;
$content-h1-font_family: $font-fredericka !default;
$content-h1-font_size: $font-size-base * 2.25 !default;
$content-h1-font_size_sm: $h1-font-size !default;
$content-h1-font_weight: 400 !default;
$content-h1-color: $primary !default;
$content-h1-color--dark: $primary !default;

/* content > p
   ========================================================================== */
$content-p-font_size: $font-size-base_sm !default;

/* ==========================================================================
   card
   ========================================================================== */
$card-background: transparent;
$card-border: none !default;
$card-base-border-radius: 0 !default;

/* card > card-title */
$card-title-font_size: $h2-font-size !default;
$card-title-color: $white !default;

/* card > card-title-link */
$card-title-link-color--hover: $white !default;

/* card > card-subtitle */
$card-subtitle-font_size: $font-size-base !default;
$card-subtitle-color: $white !default;

/* card > card-description */
$card-description-max_height: auto !default;
$card-description-margin: 0 !default;
$card-description-font_size: $font-size-base_sm !default;
$card-description-color: $white !default;

/* ==========================================================================
   owl-carousel
   ========================================================================== */
$carousel-min_height: 50vh !default;
$carousel-min_height_lg: 70vh !default;
$owl-nav-arrow-color: #272726;
